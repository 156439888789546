import Twitter from '../icon/Twitter';
import Info from '../icon/Info';

function CTAMobile(props) {

    const CTA_STYLE = 'primary';
    const TEXT_HIGHLIGHT_STYLE = 'text-secondary-500';
    const DEFAULT_HEIGHT = '400px';
    const BUTTON_SIZE = 'medium';
    const BUTTON_STATE = 'ghost';
    
    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }
    
    return (
        <div className='px-4 w-full bg-white flex items-center' style={{ height: getHeight() }}>
            <div className='flex flex-col items-center gap-6'>
                <p className={`font-inter text-h3 text-center text-neutral-800 select-text`}>
                    Join The
                    <span className={`text-error-500`}> Community</span>
                </p>
                <p className={`font-inter font-bold text-h6 text-center text-neutral-800 first-letter:select-text`}>
                    We're working hard to build the world's best decentralized sportsbook. Keep in touch with our progress by joining our community channels.
                </p>
                <div className='flex flex-col items-center gap-5'>
                    <a href="https://twitter.com/setoros">
                        <button className="btn-sm btn-black group">
                            <div className="flex flex-row gap-2 items-center">
                                <Twitter className="fill-white group-hover:fill-neutral-800 transition ease-in-out duration-500" width={14} height={14} />
                                <a>Twitter</a>
                            </div>
                        </button>
                    </a>
                    <a href="https://docs.setoros.com">
                        <button className="btn-sm btn-black group">
                            <div className="flex flex-row gap-2 items-center">
                                <Info className="fill-white group-hover:fill-neutral-800 transition ease-in-out duration-500" width={14} height={14} />
                                <a>Documentation</a>
                            </div>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default CTAMobile;

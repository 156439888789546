import { openOnClick } from '../utils';

function Drop(props) {
    const DEFAULT_WIDTH = '24px';
    const DEFAULT_HEIGHT = '24px';
    const DEFAULT_FILL_COLOR = 'fill-white';

    const getWidth = () => {
        return props.width === undefined ? DEFAULT_WIDTH : props.width;
    }

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    const getCursorType = () => {
        if (props.link !== undefined && props.link.length > 0) {
            return 'cursor-pointer';
        }
        return ''; // use default cursor type
    }

    const getClassesWithPrefix = (prefix, classNames) => {
        var classArray = [];
        var classNameArray = classNames.split(' ');
        for (var i = 0; i < classNameArray.length; i++) {
            if (classNameArray[i].startsWith(prefix)) {
                classArray.push(classNameArray[i]);
            }
        }
        return classArray;
    }

    // Assume there is only one fill class, will return first if there is more than one
    const getFillColor = () => {
        if (props.className === undefined) {
            return DEFAULT_FILL_COLOR;
        }
        var fillColorArray = getClassesWithPrefix('fill-', props.className);
        return (fillColorArray === undefined || fillColorArray.length === 0) ? DEFAULT_FILL_COLOR : fillColorArray[0];
    }

    return (
        <svg onClick={(e) => openOnClick(e, props.link)} className={`${props.className} ${getCursorType()}`} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' style={{ minWidth: getWidth(), minHeight: getHeight(), width: getWidth(), height: getHeight() }}>
            <g clipPath='url(#clip0_49_5007)'>
                <path d='M4.713 17.644C5.186 17.35 5.863 17.034 6.734 16.755C7.658 17.344 9.438 18 12 18C14.562 18 16.342 17.344 17.266 16.755C18.137 17.033 18.815 17.35 19.288 17.644C17.714 19.386 14.367 20 12 20C9.633 20 6.287 19.386 4.713 17.644ZM18.921 13.104C18.696 13.744 18.387 14.345 18.001 14.89C20.534 15.729 22 17.001 22 18C22 19.631 18.104 22 12 22C5.896 22 2 19.631 2 18C2 17.001 3.466 15.729 5.999 14.89C5.613 14.345 5.305 13.744 5.08 13.104C2.01 14.19 0 15.977 0 18C0 21.313 5.373 24 12 24C18.629 24 24 21.313 24 18C24 15.977 21.99 14.191 18.921 13.104ZM17.334 10.716C17.334 13.635 14.948 16 12 16C9.053 16 6.667 13.635 6.667 10.716C6.667 7.799 8.754 4.798 12 0C15.247 4.798 17.334 7.799 17.334 10.716ZM10 7.073C10 8.659 12.667 8.232 12.667 5.626C12.667 4.737 12.293 3.897 11.982 3.413C11.326 4.377 10 5.985 10 7.073Z' />
            </g>
            <defs>
                <clipPath id='clip0_49_5007'>
                    <rect width='24' height='24' fill='white' />
                </clipPath>
            </defs>
        </svg>

    );
}

export default Drop;
import Twitter from '../icon/Twitter';
import Info from '../icon/Info';
import HeroImageMobile from '../assets/HeroImageMobile.png';

function HeroMobile(props) {

    const HERO_STYLE = 'primary';
    const TEXT_HIGHLIGHT_STYLE = 'text-error-500';
    const DEFAULT_HEIGHT = '680px';
    const DEFAULT_NAV_BAR_HEIGHT = '50px';
    const BUTTON_SIZE = 'medium';
    const BUTTON_STATE = 'ghost';

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    const getNavBarHeight = () => {
        return props.navBarHeight === undefined ? DEFAULT_NAV_BAR_HEIGHT : props.navBarHeight;
    }

    return (
        <div className='px-4 w-full bg-neutral-900 bg-hero-image-mobile bg-cover h-[100vh]' style={{ marginTop: getNavBarHeight() }}>
            <div className='flex flex-col items-center gap-6'>
                <div className='flex flex-col items-center gap-3 mt-3'>
                    <h1 className="text-h1m text-white text-center">
                        DEFY <br/> THE <br/> <span className="text-error-500">ODDS.</span>
                    </h1>
                    <p className={`font-inter font-bold text-h6 text-center text-white select-text`}>
                        The revolutionary decentralized crypto sportsbook that lets you bet on your favorite teams from anywhere in the world from your own wallet. No signups. No deposits. No KYC.
                    </p>
                </div>
                <div className='flex flex-col items-center gap-5'>
                    <a href="https://twitter.com/setoros">
                        <button className="btn-sm btn-primary group">
                            <div className="flex flex-row gap-2 items-center">
                                <Twitter className="fill-white group-hover:fill-primary-800 transition ease-in-out duration-500" width={14} height={14} />
                                <a>Twitter</a>
                            </div>
                        </button>
                    </a>
                    <a href="https://docs.setoros.com">
                        <button className="btn-sm btn-primary group">
                            <div className="flex flex-row gap-2 items-center">
                                <Info className="fill-white group-hover:fill-primary-800 transition ease-in-out duration-500" width={14} height={14} />
                                <a>Documentation</a>
                            </div>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default HeroMobile;

import NavBarMobile from './components/NavBarMobile';
import HeroMobile from './components/HeroMobile';
import IconFeatureMobile from './components/IconFeatureMobile';
import MediaFeatureMobile1 from './components/MediaFeatureMobile1';
import MediaFeatureMobile2 from './components/MediaFeatureMobile2';
import MediaFeatureMobile3 from './components/MediaFeatureMobile3';
import CTAMobile from './components/CTAMobile';
import FooterMobile from './components/FooterMobile';

function HomepageMobile() {

    const NAV_BAR_HEIGHT = '50px';

    return (
        <>
            <NavBarMobile height={NAV_BAR_HEIGHT} />
            <HeroMobile navBarHeight={NAV_BAR_HEIGHT} />
            <IconFeatureMobile />
            <MediaFeatureMobile1 />
            <MediaFeatureMobile2 />
            <MediaFeatureMobile3 />
            <CTAMobile />
            <FooterMobile />
        </>
    );
}

export default HomepageMobile;
import Logo from '../assets/Logo';
import { getIcon, getTextColor } from '../utils';
import { DISCORD_LINK, GITHUB_LINK, HOMEPAGE_LINK, TWITTER_LINK } from '../constants';

import Twitter from '../icon/Twitter'
import Discord from '../icon/Discord'
import Github from '../icon/Github'

function Footer(props) {

    const FOOTER_STYLE = 'primary';
    const COPYRIGHT_TEXT = `© ${new Date().getFullYear()} SETOROS GROUP`;
    const COPYRIGHT_TEXT_STYLE = 'primary';
    const DEFAULT_HEIGHT = '50px';
    const LOGO_WIDTH = '151px';
    const ICON_SIZE = 'large';

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    return (
        <footer className='flex flex-row items-center justify-between px-15 w-full bg-dark-800' style={{ height: getHeight() }}>
            <Logo width={LOGO_WIDTH} height={getHeight()} link={HOMEPAGE_LINK} />
            <div>
                <p className={`font-inter font-normal text-footer text-center ${getTextColor(COPYRIGHT_TEXT_STYLE)} select-none`}>
                    {COPYRIGHT_TEXT}
                </p>
            </div>
            <div className='flex flex-row items-center justify-end p-0 gap-2 w-[88px]'>
                <Discord link="https://discord.gg/ssSRkyUmsn" className="fill-white" width={20} height={20} />
                <Twitter link="https://twitter.com/setoros" className="fill-white" width={20} height={20} />
                <Github link="https://github.com/setoros-group" className="fill-white" width={20} height={20} />
            </div>
        </footer>
    );
}

export default Footer;

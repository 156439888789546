import { getTextColor } from '../utils';
import MediaFeatureImage3 from '../assets/MediaFeatureImage3.png';

function MediaFeature3(props) {

    const MEDIA_FEATURE_3_STYLE = 'primary';
    const DEFAULT_HEIGHT = '650px';

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    return (
        <div className='flex flex-row items-center justify-end px-15 w-full bg-neutral-50 gap-5' style={{ height: getHeight() }}>
            <div className='flex flex-col items-center w-[50%] z-0'>
                <div className='w-[566px] h-[433px]'>
                    <img src={MediaFeatureImage3} alt='MediaFeature3' />
                </div>
            </div>
            <div className='flex flex-col items-start gap-3 w-[50%] z-10'>
                <p className={`font-inter font-bold text-h2 text-left text-neutral-800 select-text`}>
                    Multi-Token
                </p>
                <p className={`font-inter font-bold text-h6 text-left text-neutral-800 select-text`}>
                    Don't give up exposure to crypto with our Multi-Token Support. Bet natively in USDC, ETH, BTC, and other tokens.
                </p>
            </div>
        </div>
    );
}

export default MediaFeature3;

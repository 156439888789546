import Key from '../icon/Key';
import Drop from '../icon/Drop';
import Currency from '../icon/Currency';

function IconFeature(props) {

    const ICON_FEATURE_STYLE = 'primary';
    const ICON_FEATURE_SIZE = '3xl';
    const DEFAULT_HEIGHT = '200px';

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    return (
        <div className='flex flex-row items-center justify-evenly px-15 w-full bg-neutral-50 gap-5' style={{ height: getHeight() }}>
            <div>
                <p className={`font-inter font-bold text-h3 text-left text-neutral-800 select-text`}>
                    Key
                </p>
                <p className={`font-inter font-bold text-h4 text-left text-neutral-800 select-text`}>
                    Features
                </p>
            </div>
            <div className='flex flow-row items-center gap-5'>
                <Key className="fill-neutral-800" width={48} height={48} />
                <div>
                    <p className={`font-inter font-bold text-h3 text-left text-neutral-800 select-text`}>
                        Self
                    </p>
                    <p className={`font-inter font-bold text-h4 text-left text-neutral-800 select-text`}>
                        Custody
                    </p>
                </div>
            </div>
            <div className='flex flow-row items-center gap-5'>
                <Drop className="fill-neutral-800" width={48} height={48} />
                <div>
                    <p className={`font-inter font-bold text-h3 text-left text-neutral-800 select-text`}>
                        Deep
                    </p>
                    <p className={`font-inter font-bold text-h4 text-left text-neutral-800 select-text`}>
                        Liquidity
                    </p>
                </div>
            </div>
            <div className='flex flow-row items-center gap-5'>
                <Currency className="fill-neutral-800" width={48} height={48} />    
                <div>
                    <p className={`font-inter font-bold text-h3 text-left text-neutral-800 select-text`}>
                        Multi-
                    </p>
                    <p className={`font-inter font-bold text-h4 text-left text-neutral-800 select-text`}>
                        Token
                    </p>
                </div>
            </div>
        </div>
    );
}

export default IconFeature;
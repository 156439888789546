import Twitter from '../icon/Twitter';
import Info from '../icon/Info';

function Hero(props) {

    const DEFAULT_HEIGHT = '900px';
    const DEFAULT_TEXT_WIDTH = '613px';

    const getTextWidth = () => {
        return props.textWidth === undefined ? DEFAULT_TEXT_WIDTH : props.textWidth;
    }

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    return (
        <div className='px-15 pt-14 w-full bg-neutral-900 bg-hero-image bg-right bg-no-repeat h-[100vh]'>
            <div className='flex flex-col items-start gap-6' style={{ width: getTextWidth() }}>
                <div className='flex flex-col items-start'>
                    <h1 className="text-h1 text-white">
                        DEFY <br/> THE <br/> <span className="text-error-500">ODDS.</span>
                    </h1>
                    <p className={`font-inter text-h4 text-white text-left select-text`}>
                        The revolutionary decentralized crypto sportsbook that lets you bet on your favorite teams from anywhere in the world from your own wallet. No signups. No deposits. No KYC.
                    </p>
                </div>
                <div className='flex flex-row items-start gap-5'>
                    <a href="https://twitter.com/setoros">
                        <button className="btn-md btn-primary group">
                            <div className="flex flex-row gap-2 items-center">
                                <Twitter className="fill-white group-hover:fill-primary-800 transition ease-in-out duration-500" width={16} height={16} />
                                <a>Twitter</a>
                            </div>
                        </button>
                    </a>
                    <a href="https://docs.setoros.com">
                        <button className="btn-md btn-primary group">
                            <div className="flex flex-row gap-2 items-center">
                                <Info className="fill-white group-hover:fill-primary-800 transition ease-in-out duration-500" width={16} height={16} />
                                <a>Documentation</a>
                            </div>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Hero;

import { getTextColor } from '../utils';
import MediaFeatureImageMobile1 from '../assets/MediaFeatureImageMobile1.png';

function MediaFeatureMobile1(props) {

    const MEDIA_FEATURE_1_STYLE = 'primary';
    const DEFAULT_HEIGHT = '700px';

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    return (
        <div className='px-4 w-full bg-neutral-50' style={{ height: getHeight() }}>
            <div className='flex flex-col items-center gap-1'>
                <img className='w-auto h-full' src={MediaFeatureImageMobile1} alt='MediaFeature1' />
                <div className='flex flex-col items-center gap-3'>
                    <p className={`font-inter font-bold text-h2 text-center text-neutral-800 select-text`}>
                        Self-Custody
                    </p>
                    <p className={`font-inter font-bold text-h6 text-left text-neutral-800 select-text`}>
                        Directly place bets using your crypto wallets. No signups, no deposits, and no KYC necessary. You hold the keys to your own funds.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default MediaFeatureMobile1;
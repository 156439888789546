import { useState } from 'react';
import { getIcon, getTextColor, openOnClick } from '../utils';
import Logo from '../assets/Logo';
import Info from '../icon/Info'
import Close from '../icon/Close';
import Hamburger from '../icon/Hamburger';
import { DOC_LINK, HOMEPAGE_LINK } from '../constants';

function NavBarClosedMobile(props) {
    const [expanded, setExpanded] = useState(false);

    const DEFAULT_HEIGHT = '50px';
    const HEADER_STYLE = 'primary';
    const LOGO_WIDTH = '151px'
    const MENU_ICON_SIZE = 'large';
    const DOC_ICON_SIZE = 'medium';
    const DOC_ICON_TEXT = 'Documentation';
    const BUTTON_SIZE = 'medium';
    const BUTTON_STATE = 'enabled';
    const BUTTON_TEXT = 'OPEN APP';
    const ITEM_HEIGHT = '50px';

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    const isDisplay = () => {
        return expanded ? 'block' : 'hidden';
    }

    const toggleExpansion = () => {
        setExpanded(!expanded);
    }

    const getIconType = () => {
        if(expanded){
            return <Close className="fill-neutral-800" width={20} height={20} />
        } else {
            return <Hamburger className="fill-neutral-800" width={20} height={20} />
        }        
    }

    return (
        <nav className='flex flex-row items-center justify-between pl-4 fixed left-0 top-0 w-full bg-white z-50' style={{ height: getHeight() }}>
            <Logo className="fill-neutral-800" width={LOGO_WIDTH} height={getHeight()} link={HOMEPAGE_LINK} />
            <div className='flex flex-col items-end'>
                <div className='flex flex-col items-end absolute top-0' onClick={toggleExpansion}>
                    <div className='w-[48px] h-[48px] flex items-center'>
                        {getIconType()}
                    </div>
                    <div className={`flex flex-col items-center ${isDisplay()} bg-white ${getTextColor(HEADER_STYLE)} w-[100vw]`}>
                        <a href={DOC_LINK} target='_blank' rel='noreferrer' className='flex flex-row items-center justify-center w-full'>
                            <div className='flex flow-row items-center gap-1' style={{ height: ITEM_HEIGHT }}>
                                <Info className="fill-neutral-800" width={16} height={16} />
                                <p className={`font-inter font-bold text-p2 text-neutral-800 text-center select-none`}>
                                    Documentation
                                </p>
                            </div>
                        </a>
                        <div className='flex items-center' style={{ height: ITEM_HEIGHT }}>
                            <button onClick={(e) => openOnClick(e, 'https://app.setoros.com')} className="btn-sm btn-black">Open App</button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default NavBarClosedMobile;

import { getTextColor } from '../utils';
import MediaFeatureImage2 from '../assets/MediaFeatureImage2.png';

function MediaFeature2(props) {

    const MEDIA_FEATURE_2_STYLE = 'primary';
    const DEFAULT_HEIGHT = '650px';

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    return (
        <div className='flex flex-row items-center justify-start px-15 w-full bg-white gap-5' style={{ height: getHeight() }}>
            <div className='flex flex-col items-start gap-3 w-[50%] z-10'>
                <p className={`font-inter font-bold text-h2 text-left text-neutral-800 select-text`}>
                    Deep Liquidity
                </p>
                <p className={`font-inter font-bold text-h6 text-left text-neutral-800 select-text`}>
                    Our revolutionary Global Liquidity Pools allow bettors to tap into deep liquidity with competitive odds across all betting markets, comparable to traditional sportsbook platforms.
                </p>
            </div>
            <div className='flex flex-col items-center w-[50%] z-0'>
                <div className='w-[566px] h-[433px]'>
                    <img src={MediaFeatureImage2} alt='MediaFeature2' />
                </div>
            </div>
        </div>
    );
}

export default MediaFeature2;

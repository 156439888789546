import Close from './icon/Close';
import Currency from './icon/Currency';
import Discord from './icon/Discord';
import Drop from './icon/Drop';
import Github from './icon/Github';
import Hamburger from './icon/Hamburger';
import Info from './icon/Info';
import Key from './icon/Key';
import Layer from './icon/Layer';
import Mail from './icon/Mail';
import Twitter from './icon/Twitter';

const colorVariants = {
    primary: 'bg-primary-500 hover:bg-primary-400 active:bg-primary-600',
    secondary: 'bg-secondary-500 hover:bg-secondary-400 active:bg-secondary-600',
    success: 'bg-success-500 hover:bg-success-400 active:bg-success-600',
    info: 'bg-info-500 hover:bg-info-400 active:bg-info-600',
    warning: 'bg-warning-500 hover:bg-warning-400 active:bg-warning-600',
    error: 'bg-error-500 hover:bg-error-400 active:bg-error-600',
}

const disabledColor = 'bg-neutral-300'

const ghostColorVariants = {
    primary: 'bg-transparent hover:bg-primary-400 active:bg-primary-600',
    secondary: 'bg-transparent hover:bg-secondary-400 active:bg-secondary-600',
    success: 'bg-transparent hover:bg-success-400 active:bg-success-600',
    info: 'bg-transparent hover:bg-info-400 active:bg-info-600',
    warning: 'bg-transparent hover:bg-warning-400 active:bg-warning-600',
    error: 'bg-transparent hover:bg-error-400 active:bg-error-600',
}

const borderColorVariants = {
    primary: 'border-primary-500 hover:border-primary-400 active:border-primary-600',
    secondary: 'border-secondary-500 hover:border-secondary-400 active:border-secondary-600',
    success: 'border-success-500 hover:border-success-400 active:border-success-600',
    info: 'border-info-500 hover:border-info-400 active:border-info-600',
    warning: 'border-warning-500 hover:border-warning-400 active:border-warning-600',
    error: 'border-error-500 hover:border-error-400 active:border-error-600'
}

const disabledBorderColorVariants = {
    primary: 'border-primary-500',
    secondary: 'border-secondary-500',
    success: 'border-success-500',
    info: 'border-info-500',
    warning: 'border-warning-500',
    error: 'border-error-500'
}

const textColorVariants = {
    primary: 'text-primary-50',
    secondary: 'text-secondary-50',
    success: 'text-success-50',
    info: 'text-info-50',
    warning: 'text-warning-50',
    error: 'text-error-50',
    enabled: 'text-white',
    disabled: 'text-white',
    ghost: 'text-white'
}

const strokeColorVariants = {
    primary: 'stroke-primary-50',
    secondary: 'stroke-secondary-50',
    success: 'stroke-success-50',
    info: 'stroke-info-50',
    warning: 'stroke-warning-50',
    error: 'stroke-error-50',
    enabled: 'stroke-white',
    disabled: 'stroke-white'
}

const fillColorVariants = {
    primary: 'fill-primary-50',
    secondary: 'fill-secondary-50',
    success: 'fill-success-50',
    info: 'fill-info-50',
    warning: 'fill-warning-50',
    error: 'fill-error-50',
    enabled: 'fill-white',
    disabled: 'fill-white'
}

const iconWidthVariants = {
    'small': '16px',
    'medium': '20px',
    'large': '24px',
    '2xl': '36px',
    '3xl': '48px'
}

const iconHeightVariants = {
    'small': '16px',
    'medium': '20px',
    'large': '24px',
    '2xl': '36px',
    '3xl': '48px'
}

export const isValidState = (state) => {
    return ['enabled', 'disabled', 'ghost'].includes(state);
}

export const isValidStyles = (styles) => {
    return ['primary', 'secondary', 'success', 'info', 'warning', 'error'].includes(styles);
}

export const isValidSize = (styles) => {
    return ['small', 'medium', 'large', '2xl', '3xl'].includes(styles);
}

export const getBgColor = (state, styles) => {
    if (state === undefined || !isValidState(state) || styles === undefined || !isValidStyles(styles)) {
        return disabledColor;
    }
    if (state === 'disabled') {
        return disabledColor;
    }
    if (state === 'ghost') {
        return ghostColorVariants[styles];
    }
    return colorVariants[styles];
}

export const getButtonIconColor = (state, styles, icon) => {
    if (state === undefined || !isValidState(state) || styles === undefined || !isValidStyles(styles)) {
        return '';
    }
    if (state === 'enabled' || state === 'disabled') {
        return icon === 'mail' ? strokeColorVariants[state] : fillColorVariants[state];
    }
    return icon === 'mail' ? strokeColorVariants[styles] : fillColorVariants[styles];
}

export const getIconColor = (styles, icon) => {
    if (styles === undefined || !isValidStyles(styles)) {
        return '';
    }
    return styles
}

export const getIconWidth = (size) => {
    if (size === undefined || !isValidSize(size)) {
        return iconWidthVariants['small'];
    }
    return iconWidthVariants[size];
}

export const getIconHeight = (size) => {
    if (size === undefined || !isValidSize(size)) {
        return iconHeightVariants['small'];
    }
    return iconHeightVariants[size];
}

export const createIcon = (icon, iconColor, size, link) => {
    var iconWidth = getIconWidth(size);
    var iconHeight = getIconHeight(size);
    if (icon === 'close') {
        return <Close className={iconColor} width={iconWidth} height={iconHeight} link={link} />
    }
    if (icon === 'currency') {
        return <Currency className={iconColor} width={iconWidth} height={iconHeight} link={link} />
    }
    if (icon === 'discord') {
        return <Discord className={iconColor} width={iconWidth} height={iconHeight} link={link} />
    }
    if (icon === 'drop') {
        return <Drop className={iconColor} width={iconWidth} height={iconHeight} link={link} />
    }
    if (icon === 'github') {
        return <Github className={iconColor} width={iconWidth} height={iconHeight} link={link} />
    }
    if (icon === 'hamburger') {
        return <Hamburger className={iconColor} width={iconWidth} height={iconHeight} link={link} />
    }
    if (icon === 'info') {
        return <Info className={iconColor} width={iconWidth} height={iconHeight} link={link} />
    }
    if (icon === 'key') {
        return <Key className={iconColor} width={iconWidth} height={iconHeight} link={link} />
    }
    if (icon === 'layer') {
        return <Layer className={iconColor} width={iconWidth} height={iconHeight} link={link} />
    }
    if (icon === 'mail') {
        return <Mail className={iconColor} width={iconWidth} height={iconHeight} link={link} />
    }
    if (icon === 'twitter') {
        return <Twitter className={iconColor} width={iconWidth} height={iconHeight} link={link} />
    }
    return <></>;
}

export const getButtonIcon = (icon, size, state, styles, link) => {
    var iconColor = getButtonIconColor(state, styles, icon);
    return createIcon(icon, iconColor, size, link)
}

export const getIcon = (icon, size, styles, link) => {
    //var iconColor = getIconColor(styles, icon);
    return createIcon(icon, styles, size, link)
}

export const getTextColor = (styles) => {
    if (styles === undefined || !isValidStyles(styles)) {
        return '';
    }
    return textColorVariants[styles];
}

export const getButtonTextColor = (state, styles) => {
    if (state === undefined || !isValidState(state) || styles === undefined || !isValidStyles(styles)) {
        return '';
    }
    if (state === 'enabled' || state === 'disabled') {
        return textColorVariants[state];
    }
    return textColorVariants[styles];
}

export const getBorderColor = (state, styles) => {
    if (state === undefined || !isValidState(state) || styles === undefined || !isValidStyles(styles)) {
        return '';
    }
    if (state === 'disabled' || state === 'ghost') {
        return disabledBorderColorVariants[styles];
    }
    return borderColorVariants[styles];
}

// Open link in new tab by default
export const openOnClick = (e, link) => {
    if (link !== undefined && link.length > 0) {
        window.open(link, '_blank');
    }
}
import { openOnClick } from '../utils';

function Twitter(props) {
    const DEFAULT_WIDTH = '24px';
    const DEFAULT_HEIGHT = '24px';
    const DEFAULT_FILL_COLOR = 'fill-white';

    const getWidth = () => {
        return props.width === undefined ? DEFAULT_WIDTH : props.width;
    }

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    const getCursorType = () => {
        if (props.link !== undefined && props.link.length > 0) {
            return 'cursor-pointer';
        }
        return ''; // use default cursor type
    }

    const getClassesWithPrefix = (prefix, classNames) => {
        var classArray = [];
        var classNameArray = classNames.split(' ');
        for (var i = 0; i < classNameArray.length; i++) {
            if (classNameArray[i].startsWith(prefix)) {
                classArray.push(classNameArray[i]);
            }
        }
        return classArray;
    }

    // Assume there is only one fill class, will return first if there is more than one
    const getFillColor = () => {
        if (props.className === undefined) {
            return DEFAULT_FILL_COLOR;
        }
        var fillColorArray = getClassesWithPrefix('fill-', props.className);
        return (fillColorArray === undefined || fillColorArray.length === 0) ? DEFAULT_FILL_COLOR : fillColorArray[0];
    }

    return (
        <svg onClick={(e) => openOnClick(e, props.link)} className={`${props.className} ${getCursorType()}`} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' style={{ minWidth: getWidth(), minHeight: getHeight(), width: getWidth(), height: getHeight() }}>
            <g clipPath='url(#clip0_49_12477)'>
                <path d='M24 4.55699C23.117 4.94899 22.168 5.21299 21.172 5.33199C22.189 4.72299 22.97 3.75799 23.337 2.60799C22.386 3.17199 21.332 3.58199 20.21 3.80299C19.313 2.84599 18.032 2.24799 16.616 2.24799C13.437 2.24799 11.101 5.21399 11.819 8.29299C7.728 8.08799 4.1 6.12799 1.671 3.14899C0.381 5.36199 1.002 8.25699 3.194 9.72299C2.388 9.69699 1.628 9.47599 0.965 9.10699C0.911 11.388 2.546 13.522 4.914 13.997C4.221 14.185 3.462 14.229 2.69 14.081C3.316 16.037 5.134 17.46 7.29 17.5C5.22 19.123 2.612 19.848 0 19.54C2.179 20.937 4.768 21.752 7.548 21.752C16.69 21.752 21.855 14.031 21.543 7.10599C22.505 6.41099 23.34 5.54399 24 4.55699Z' />
            </g>
            <defs>
                <clipPath id='clip0_49_12477'>
                    <rect width='24' height='24' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Twitter;
import { getTextColor } from '../utils';
import MediaFeatureImage1 from '../assets/MediaFeatureImage1.png';

function MediaFeature1(props) {

    const MEDIA_FEATURE_1_STYLE = 'primary';
    const DEFAULT_HEIGHT = '650px';

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    return (
        <div className='flex flex-row items-center justify-end px-15 w-full bg-neutral-50 gap-5' style={{ height: getHeight() }}>
            <div className='flex flex-col items-center w-[50%] z-0'>
                <div className='w-[614px] h-[433px]'>
                    <img src={MediaFeatureImage1} alt='MediaFeature1' />
                </div>
            </div>
            <div className='flex flex-col items-start gap-3 w-[50%] z-10'>
                <p className={`font-inter font-bold text-h2 text-left text-neutral-800 select-text`}>
                    Self-Custody
                </p>
                <p className={`font-inter font-bold text-h6 text-left text-neutral-800 select-text`}>
                    Directly place bets using your crypto wallets. No signups, no deposits, and no KYC necessary. You hold the keys to your own funds.
                </p>
            </div>
        </div>
    );
}

export default MediaFeature1;

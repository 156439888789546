import { openOnClick } from '../utils';

function Currency(props) {
    const DEFAULT_WIDTH = '24px';
    const DEFAULT_HEIGHT = '24px';
    const DEFAULT_FILL_COLOR = 'fill-white';

    const getWidth = () => {
        return props.width === undefined ? DEFAULT_WIDTH : props.width;
    }

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    const getCursorType = () => {
        if (props.link !== undefined && props.link.length > 0) {
            return 'cursor-pointer';
        }
        return ''; // use default cursor type
    }

    const getClassesWithPrefix = (prefix, classNames) => {
        var classArray = [];
        var classNameArray = classNames.split(' ');
        for (var i = 0; i < classNameArray.length; i++) {
            if (classNameArray[i].startsWith(prefix)) {
                classArray.push(classNameArray[i]);
            }
        }
        return classArray;
    }

    // Assume there is only one fill class, will return first if there is more than one
    const getFillColor = () => {
        if (props.className === undefined) {
            return DEFAULT_FILL_COLOR;
        }
        var fillColorArray = getClassesWithPrefix('fill-', props.className);
        return (fillColorArray === undefined || fillColorArray.length === 0) ? DEFAULT_FILL_COLOR : fillColorArray[0];
    }

    return (
        <svg onClick={(e) => openOnClick(e, props.link)} className={`${props.className} ${getCursorType()}`} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' style={{ minWidth: getWidth(), minHeight: getHeight(), width: getWidth(), height: getHeight() }}>
            <g clipPath='url(#clip0_49_5012)'>
                <path d='M12 0C9.238 0 7 2.238 7 5C7 7.762 9.238 10 12 10C14.762 10 17 7.762 17 5C17 2.238 14.762 0 12 0ZM12.25 7.474V8H11.75V7.501C11.232 7.492 10.697 7.369 10.25 7.138L10.478 6.316C10.956 6.502 11.592 6.699 12.09 6.587C12.664 6.456 12.782 5.865 12.147 5.581C11.682 5.364 10.258 5.179 10.258 3.96C10.258 3.278 10.778 2.668 11.75 2.534V2H12.25V2.509C12.611 2.519 13.018 2.582 13.471 2.719L13.29 3.543C12.906 3.408 12.482 3.285 12.068 3.311C11.324 3.354 11.258 3.999 11.778 4.269C12.633 4.671 13.75 4.969 13.75 6.041C13.752 6.9 13.078 7.357 12.25 7.474ZM12 14C9.238 14 7 16.238 7 19C7 21.762 9.238 24 12 24C14.762 24 17 21.762 17 19C17 16.238 14.762 14 12 14ZM12 23C9.795 23 8 21.205 8 19C8 16.795 9.795 15 12 15C14.206 15 16 16.795 16 19C16 21.205 14.206 23 12 23ZM21.577 9.387C22.81 11.739 23.125 16.188 18.992 19.143C19.011 18.261 18.879 17.437 18.556 16.571C20.669 14.827 20.607 12.307 19.749 10.337L17.372 11.573L18.968 6.391L24 8.128L21.577 9.387ZM2.423 14.998C1.19 12.646 0.963 7.852 5.095 4.897C5.076 5.779 5.209 6.604 5.531 7.469C3.417 9.214 3.392 12.078 4.25 14.049L6.627 12.813L5.031 17.995L0 16.257L2.423 14.998ZM11.999 22V21.244H11.751V22H11.249V21.244H10.007L10.134 20.5H10.404C10.623 20.5 10.751 20.287 10.751 20.073V17.851C10.751 17.643 10.629 17.5 10.41 17.5H10V16.75H11.249V16H11.751V16.75H11.999V16H12.501V16.763C13.579 16.8 14.007 17.208 14.13 17.67C14.273 18.215 13.914 18.672 13.605 18.784C13.98 18.88 14.501 19.157 14.501 19.797C14.501 20.668 13.828 21.251 12.502 21.251V22H11.999ZM11.751 19.249V20.5C12.742 20.5 13.422 20.406 13.422 19.871C13.422 19.296 12.688 19.249 11.751 19.249ZM11.751 18.75C12.303 18.75 13.146 18.71 13.146 18.124C13.146 17.625 12.625 17.499 11.751 17.499V18.75Z' />
            </g>
            <defs>
                <clipPath id='clip0_49_5012'>
                    <rect width='24' height='24' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Currency;
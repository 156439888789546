import Twitter from '../icon/Twitter';
import Info from '../icon/Info';

function CTA(props) {

    const DEFAULT_HEIGHT = '500px';
    const DEFAULT_TEXT_WIDTH = '480px';

    const getTextWidth = () => {
        return props.textWidth === undefined ? DEFAULT_TEXT_WIDTH : props.textWidth;
    }

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    return (
        <div className='flex flex-row items-center w-full bg-white place-content-center' style={{ height: getHeight() }}>
            <div className='flex flex-col items-center gap-6' style={{ width: getTextWidth() }}>
                <p className={`font-inter text-h3 text-center text-neutral-800 select-text`}>
                    Join The
                    <br />
                    <span className={`text-error-500`}>Community</span>
                </p>
                <p className={`font-inter font-bold text-p1 text-center text-neutral-800 select-text`}>
                    We're working hard to build the world's best decentralized sportsbook. Keep in touch with our progress by joining our community channels.
                </p>
                <div className='flex flex-row items-center gap-5'>
                    <a href="https://twitter.com/setoros">
                        <button className="btn-md btn-black group">
                            <div className="flex flex-row gap-2 items-center">
                                <Twitter className="fill-white group-hover:fill-neutral-800 transition ease-in-out duration-500" width={16} height={16} />
                                <a>Twitter</a>
                            </div>
                        </button>
                    </a>
                    <a href="https://docs.setoros.com">
                        <button className="btn-md btn-black group">
                            <div className="flex flex-row gap-2 items-center">
                                <Info className="fill-white group-hover:fill-neutral-800 transition ease-in-out duration-500" width={16} height={16} />
                                <a>Documentation</a>
                            </div>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default CTA;

import { openOnClick } from '../utils';

function Key(props) {
    const DEFAULT_WIDTH = '24px';
    const DEFAULT_HEIGHT = '13px';
    const DEFAULT_FILL_COLOR = 'fill-white';

    const getWidth = () => {
        return props.width === undefined ? DEFAULT_WIDTH : props.width;
    }

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    const getCursorType = () => {
        if (props.link !== undefined && props.link.length > 0) {
            return 'cursor-pointer';
        }
        return ''; // use default cursor type
    }

    const getClassesWithPrefix = (prefix, classNames) => {
        var classArray = [];
        var classNameArray = classNames.split(' ');
        for (var i = 0; i < classNameArray.length; i++) {
            if (classNameArray[i].startsWith(prefix)) {
                classArray.push(classNameArray[i]);
            }
        }
        return classArray;
    }

    // Assume there is only one fill class, will return first if there is more than one
    const getFillColor = () => {
        if (props.className === undefined) {
            return DEFAULT_FILL_COLOR;
        }
        var fillColorArray = getClassesWithPrefix('fill-', props.className);
        return (fillColorArray === undefined || fillColorArray.length === 0) ? DEFAULT_FILL_COLOR : fillColorArray[0];
    }

    return (
        <svg onClick={(e) => openOnClick(e, props.link)} className={`${props.className} ${getCursorType()}`} viewBox='0 0 24 13' fill='none' xmlns='http://www.w3.org/2000/svg' style={{ minWidth: getWidth(), minHeight: getHeight(), width: getWidth(), height: getHeight() }}>
            <path fillRule='evenodd' clipRule='evenodd' d='M24 6.5C24 10.113 21.049 13 17.525 13C15.371 13 13.424 11.786 12.187 10H9.305L8.259 8.987L6.957 10.006L5.595 8.931L4.188 10.012L0 6.564L3.346 3H12.187C13.332 1.317 15.291 0 17.526 0C21.023 0 24 2.866 24 6.5ZM13.309 8C14.289 9.671 15.586 11 17.526 11C19.938 11 22 9.014 22 6.5C22 4.002 19.956 2 17.521 2C15.466 2 14.229 3.433 13.309 5H4.212L2.919 6.376L4.231 7.457L5.611 6.396L6.962 7.462L8.399 6.339L10.114 8H13.309ZM19 4.875C19.828 4.875 20.5 5.547 20.5 6.375C20.5 7.203 19.828 7.875 19 7.875C18.172 7.875 17.5 7.203 17.5 6.375C17.5 5.547 18.172 4.875 19 4.875Z' />
        </svg>

    );
}

export default Key;
import { openOnClick } from '../utils';

function Close(props) {
    const DEFAULT_WIDTH = '14px';
    const DEFAULT_HEIGHT = '15px';
    const DEFAULT_FILL_COLOR = 'fill-white';

    const getWidth = () => {
        return props.width === undefined ? DEFAULT_WIDTH : props.width;
    }

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    const getCursorType = () => {
        if (props.link !== undefined && props.link.length > 0) {
            return 'cursor-pointer';
        }
        return ''; // use default cursor type
    }

    const getClassesWithPrefix = (prefix, classNames) => {
        var classArray = [];
        var classNameArray = classNames.split(' ');
        for (var i = 0; i < classNameArray.length; i++) {
            if (classNameArray[i].startsWith(prefix)) {
                classArray.push(classNameArray[i]);
            }
        }
        return classArray;
    }

    // Assume there is only one fill class, will return first if there is more than one
    const getFillColor = () => {
        if (props.className === undefined) {
            return DEFAULT_FILL_COLOR;
        }
        var fillColorArray = getClassesWithPrefix('fill-', props.className);
        return (fillColorArray === undefined || fillColorArray.length === 0) ? DEFAULT_FILL_COLOR : fillColorArray[0];
    }

    return (
        <svg onClick={(e) => openOnClick(e, props.link)} className={`${props.className} ${getCursorType()}`} viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg' style={{ minWidth: getWidth(), minHeight: getHeight(), width: getWidth(), height: getHeight() }}>
            <path fillRule='evenodd' clipRule='evenodd' d='M7 6.93003L12.719 1.21003C12.865 1.06403 13.058 0.991028 13.25 0.991028C13.654 0.991028 14 1.31503 14 1.74003C14 1.93303 13.927 2.12503 13.781 2.27203L8.061 7.99103L13.78 13.71C13.927 13.857 14 14.049 14 14.241C14 14.668 13.651 14.991 13.25 14.991C13.058 14.991 12.865 14.918 12.719 14.772L7 9.05303L1.281 14.772C1.135 14.918 0.942 14.991 0.75 14.991C0.349 14.991 0 14.668 0 14.241C0 14.049 0.0729998 13.857 0.22 13.71L5.939 7.99103L0.219 2.27203C0.0729999 2.12503 0 1.93303 0 1.74003C0 1.31503 0.346 0.991028 0.75 0.991028C0.942 0.991028 1.135 1.06403 1.281 1.21003L7 6.93003Z' />
        </svg>
    );
}

export default Close;
import React from 'react';
import ReactDOM from 'react-dom/client';
import MediaQuery from 'react-responsive';
import './index.css';
import HomepageDesktop from './HomepageDesktop';
import HomepageMobile from './HomepageMobile';
// import TestPage from './samples/TestPage';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
const breakPoint = 768 // use Desktop version for specified value or above
root.render(
    <React.StrictMode>
        <div>
            <MediaQuery maxWidth={breakPoint - 1}>
                <HomepageMobile />
            </MediaQuery>
            <MediaQuery minWidth={breakPoint}>
                <HomepageDesktop />
            </MediaQuery>
        </div>
        {/* <TestPage/> */}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

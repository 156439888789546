import { openOnClick } from '../utils';

function Info(props) {
    const DEFAULT_WIDTH = '20px';
    const DEFAULT_HEIGHT = '20px';
    const DEFAULT_FILL_COLOR = 'fill-white';

    const getWidth = () => {
        return props.width === undefined ? DEFAULT_WIDTH : props.width;
    }

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    const getCursorType = () => {
        if (props.link !== undefined && props.link.length > 0) {
            return 'cursor-pointer';
        }
        return ''; // use default cursor type
    }

    const getClassesWithPrefix = (prefix, classNames) => {
        var classArray = [];
        var classNameArray = classNames.split(' ');
        for (var i = 0; i < classNameArray.length; i++) {
            if (classNameArray[i].startsWith(prefix)) {
                classArray.push(classNameArray[i]);
            }
        }
        return classArray;
    }

    // Assume there is only one fill class, will return first if there is more than one
    const getFillColor = () => {
        if (props.className === undefined) {
            return DEFAULT_FILL_COLOR;
        }
        var fillColorArray = getClassesWithPrefix('fill-', props.className);
        return (fillColorArray === undefined || fillColorArray.length === 0) ? DEFAULT_FILL_COLOR : fillColorArray[0];
    }

    return (
        <svg onClick={(e) => openOnClick(e, props.link)} className={`${props.className} ${getCursorType()}`} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' style={{ minWidth: getWidth(), minHeight: getHeight(), width: getWidth(), height: getHeight() }}>
            <path d='M10.0019 0.00500488C15.5199 0.00500488 19.9999 4.485 19.9999 10.002C19.9999 15.52 15.5199 20 10.0019 20C4.48488 20 0.00488281 15.52 0.00488281 10.002C0.00488281 4.485 4.48488 0.00500488 10.0019 0.00500488ZM10.0019 1.505C5.31188 1.505 1.50488 5.312 1.50488 10.002C1.50488 14.692 5.31188 18.5 10.0019 18.5C14.6919 18.5 18.4999 14.692 18.4999 10.002C18.4999 5.312 14.6919 1.505 10.0019 1.505ZM10.0019 8.005C9.58788 8.005 9.25188 8.341 9.25188 8.755V14.255C9.25188 14.669 9.58788 15.005 10.0019 15.005C10.4159 15.005 10.7519 14.669 10.7519 14.255V8.755C10.7519 8.341 10.4159 8.005 10.0019 8.005ZM9.99988 5.005C10.5519 5.005 10.9999 5.453 10.9999 6.005C10.9999 6.557 10.5519 7.005 9.99988 7.005C9.44788 7.005 8.99988 6.557 8.99988 6.005C8.99988 5.453 9.44788 5.005 9.99988 5.005Z' />
        </svg>
    );
}

export default Info;
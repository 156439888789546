import NavBar from './components/NavBar';
import Hero from './components/Hero';
import IconFeature from './components/IconFeature';
import MediaFeature1 from './components/MediaFeature1';
import MediaFeature2 from './components/MediaFeature2';
import MediaFeature3 from './components/MediaFeature3';
import CTA from './components/CTA';
import Footer from './components/Footer';

function HomepageDesktop() {
    return (
        <>
            <NavBar />
            <Hero />
            <IconFeature />
            <MediaFeature1 />
            <MediaFeature2 />
            <MediaFeature3 />
            <CTA />
            <Footer />
        </>
    );
}

export default HomepageDesktop;
import { getTextColor } from '../utils';
import MediaFeatureImageMobile2 from '../assets/MediaFeatureImageMobile2.png';

function MediaFeatureMobile2(props) {

    const MEDIA_FEATURE_2_STYLE = 'primary';
    const DEFAULT_HEIGHT = '700px';

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    return (
        <div className='px-4 w-full bg-white' style={{ height: getHeight() }}>
            <div className='flex flex-col items-center gap-1'>
                <img className='w-auto h-full' src={MediaFeatureImageMobile2} alt='MediaFeature2' />
                <div className='flex flex-col items-center gap-3'>
                    <p className={`font-inter font-bold text-h2 text-center text-neutral-800 select-text`}>
                        Deep Liquidity
                    </p>
                    <p className={`font-inter font-bold text-h6 text-left text-neutral-800 select-text`}>
                    Our revolutionary Global Liquidity Pools allow bettors to tap into deep liquidity with competitive odds across all betting markets, comparable to traditional sportsbook platforms.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default MediaFeatureMobile2;
import Key from '../icon/Key';
import Drop from '../icon/Drop';
import Currency from '../icon/Currency';

function IconFeatureMobile(props) {

    const ICON_FEATURE_STYLE = 'primary';
    const ICON_FEATURE_SIZE = '2xl';
    const DEFAULT_HEIGHT = '240px';
    const DEFAULT_ITEM_HEIGHT = '64px';

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    const getItemHeight = () => {
        return props.itemHeight === undefined ? DEFAULT_ITEM_HEIGHT : props.itemHeight;
    }

    return (
        <div className='flex flex-col items-center w-full bg-neutral-50 justify-center' style={{ height: getHeight() }}>
            <p className={`font-inter font-bold text-h5 text-center text-neutral-800 select-text mt-4`}>
                Key Features
            </p>
            <div className='flex flex-col items-start'>
                <div className='flex flow-row items-center gap-5' style={{ height: getItemHeight() }}>
                    <Key className="fill-neutral-800" width={20} height={20} />                    
                    <p className={`font-inter font-bold text-h5 text-left text-neutral-800 select-text`}>
                        Self Custody
                    </p>
                </div>
                <div className='flex flow-row items-center gap-5' style={{ height: getItemHeight() }}>
                    <Drop className="fill-neutral-800" width={20} height={20} />                    
                    <p className={`font-inter font-bold text-h5 text-left text-neutral-800 select-text`}>
                        Deep Liquidity
                    </p>
                </div>
                <div className='flex flow-row items-center gap-5' style={{ height: getItemHeight() }}>
                    <Currency className="fill-neutral-800" width={20} height={20} />
                    <p className={`font-inter font-bold text-h5 text-left text-neutral-800 select-text`}>
                        Multi-Token
                    </p>
                </div>
            </div>
        </div>
    );
}

export default IconFeatureMobile;
import { openOnClick } from '../utils';

function SetorosLogo(props) {
    const DEFAULT_WIDTH = '600px';
    const DEFAULT_HEIGHT = '199px';
    const DEFAULT_FILL_COLOR = 'fill-primary-50'; // #FBFBFB

    const getWidth = () => {
        return props.width === undefined ? DEFAULT_WIDTH : props.width;
    }

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    const getCursorType = () => {
        if (props.link !== undefined && props.link.length > 0) {
            return 'cursor-pointer';
        }
        return ''; // use default cursor type
    }

    const getClassesWithPrefix = (prefix, classNames) => {
        var classArray = [];
        var classNameArray = classNames.split(' ');
        for (var i = 0; i < classNameArray.length; i++) {
            if (classNameArray[i].startsWith(prefix)) {
                classArray.push(classNameArray[i]);
            }
        }
        return classArray;
    }

    const getFillColor = () => {
        if (props.className === undefined) {
            return DEFAULT_FILL_COLOR;
        }
        var fillColorArray = getClassesWithPrefix('fill-', props.className);
        return (fillColorArray === undefined || fillColorArray.length === 0) ? DEFAULT_FILL_COLOR : fillColorArray[0];
    }

    return (
        <svg onClick={(e) => openOnClick(e, props.link)} className={`${getFillColor()} ${getCursorType()}`} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 600 199" xmlSpace="preserve" style={{ minWidth: getWidth(), minHeight: getHeight(), width: getWidth(), height: getHeight() }}>
            <path fill="none" opacity="1.000000" stroke="none"
                d="
                M429.000000,200.000000 
                    C286.044586,200.000000 143.589172,200.000000 1.066875,200.000000 
                    C1.066875,133.732056 1.066875,67.464035 1.066875,1.098005 
                    C200.999985,1.098005 400.999969,1.098005 600.973083,1.098005 
                    C600.973083,67.410751 600.973083,133.705383 600.973083,200.000000 
                    C543.797424,200.000000 486.648712,200.000000 429.000000,200.000000 
                M311.686157,148.282928 
                    C321.882782,143.330719 329.198975,135.541931 332.693939,124.842957 
                    C337.866089,109.009789 336.084808,93.879761 325.858582,80.217827 
                    C348.425079,71.010017 361.965454,55.178131 358.261749,41.969715 
                    C348.265900,56.292141 333.369965,59.416702 317.666351,60.018612 
                    C302.207031,60.611164 286.711273,60.860157 271.247437,60.524445 
                    C259.865723,60.277355 248.256485,59.534687 238.092697,53.605667 
                    C233.111725,50.700035 228.900238,46.475296 223.047226,41.818619 
                    C224.399994,46.733910 225.167343,50.766766 226.609848,54.541630 
                    C232.001678,68.651344 243.188126,76.131500 257.783966,79.998497 
                    C250.071350,89.982323 247.228989,100.562263 247.969254,112.313225 
                    C248.920181,127.408180 255.807404,139.021118 268.635712,146.554184 
                    C282.171326,154.502609 296.561432,154.711349 311.686157,148.282928 
                M422.447754,110.311966 
                    C423.826263,116.392906 424.377228,122.818954 426.741669,128.488739 
                    C433.290100,144.191391 452.117767,155.241653 472.751099,152.093399 
                    C489.267120,149.573380 501.105011,141.163925 506.920715,125.522697 
                    C513.100220,108.903084 511.132111,92.908249 499.327850,79.327995 
                    C487.259674,65.444130 467.171143,61.137005 450.148712,67.702591 
                    C433.157104,74.256310 421.916840,89.824776 422.447754,110.311966 
                M366.213348,67.002357 
                    C365.152222,67.913948 364.007111,68.745193 363.044617,69.751129 
                    C359.047852,73.928398 355.385345,78.482307 351.029877,82.236313 
                    C348.752502,84.199203 347.943787,85.882576 347.965240,88.760498 
                    C348.096802,106.403130 348.031158,124.047234 348.031219,141.690842 
                    C348.031250,144.809357 348.031219,147.927856 348.031219,150.987061 
                    C354.607391,150.987061 360.666504,150.987061 367.126892,150.987061 
                    C367.126892,141.141632 367.126892,131.558823 367.126892,121.862343 
                    C369.003784,121.862343 370.510162,122.080971 371.929932,121.822983 
                    C376.993469,120.902916 379.628754,123.146126 381.809204,127.677925 
                    C385.239197,134.806763 389.452209,141.564011 393.459351,148.404968 
                    C394.128693,149.547638 395.406616,151.096466 396.450409,151.133560 
                    C403.326996,151.377945 410.216522,151.259735 418.058929,151.259735 
                    C412.110962,140.972809 406.761963,131.520157 401.176788,122.209167 
                    C399.840393,119.981285 399.407410,118.925903 402.003265,117.189056 
                    C413.080322,109.777512 417.349213,97.832077 413.649933,85.680099 
                    C410.270569,74.579025 399.326111,67.026283 386.559814,67.000893 
                    C380.068329,66.987976 373.576782,66.998589 366.213348,67.002357 
                M71.446358,133.155640 
                    C66.304016,133.786575 61.093208,135.264221 56.034973,134.855164 
                    C47.923267,134.199173 42.295383,128.598465 37.115250,122.260818 
                    C32.901012,127.042908 28.989302,131.481705 25.149784,135.838577 
                    C37.525024,153.344177 63.769196,156.846298 80.599335,148.313019 
                    C94.922241,141.050980 97.578529,123.310020 90.659782,112.967911 
                    C85.411110,105.122215 76.897995,102.489845 68.405624,99.987007 
                    C63.511314,98.544571 58.406750,97.511719 53.863686,95.324707 
                    C51.935909,94.396683 49.964325,90.794060 50.273335,88.748505 
                    C50.594330,86.623596 53.332809,84.093666 55.585793,83.227005 
                    C58.363464,82.158493 62.025494,81.720642 64.782944,82.617119 
                    C70.426628,84.451935 75.721535,87.359558 82.185760,90.290466 
                    C84.892784,87.173187 88.573830,82.934280 92.372871,78.559502 
                    C82.911011,63.781097 60.011856,62.288696 47.059700,67.653183 
                    C34.999325,72.648300 28.573471,81.709366 29.263874,92.037086 
                    C30.061157,103.963615 37.195251,111.859360 50.554928,115.708931 
                    C56.722309,117.486046 63.014622,118.979294 68.958450,121.336983 
                    C74.063515,123.361969 74.940872,127.813339 71.446358,133.155640 
                M572.335266,145.935822 
                    C573.281616,145.004974 574.257263,144.101913 575.169861,143.139008 
                    C585.600708,132.132721 584.144409,114.199890 571.437744,106.443779 
                    C565.660339,102.917282 558.617188,101.456039 552.133362,99.098618 
                    C548.310242,97.708572 544.095276,96.989914 540.705078,94.911469 
                    C535.764160,91.882256 536.719604,85.340408 542.250183,83.456612 
                    C545.137573,82.473106 548.713318,81.651031 551.455383,82.481720 
                    C557.441223,84.295059 563.092712,87.211914 569.504089,89.943466 
                    C572.442078,87.213699 576.437012,83.501953 580.638245,79.598511 
                    C579.507324,78.165115 578.585510,76.827011 577.496582,75.642532 
                    C565.256836,62.329006 538.793335,61.109119 525.192383,73.206825 
                    C512.994690,84.056381 514.138184,102.335167 527.979553,110.918198 
                    C532.515625,113.731003 537.998657,115.045326 543.095642,116.916939 
                    C548.000244,118.717903 553.095642,120.059624 557.856812,122.168518 
                    C560.856628,123.497246 562.235352,126.779083 560.891663,129.754044 
                    C559.934509,131.873154 557.408508,134.648361 555.497803,134.715469 
                    C549.822632,134.914795 543.679932,135.039719 538.522888,133.080414 
                    C533.233459,131.070816 528.891968,126.566208 523.989258,123.048592 
                    C520.403809,127.174667 516.544373,131.616028 512.775940,135.952667 
                    C526.250549,154.782318 555.771423,157.058456 572.335266,145.935822 
                M123.500725,151.254654 
                    C137.577148,151.254654 151.653564,151.254654 165.747559,151.254654 
                    C165.747559,145.297836 165.747559,139.880798 165.747559,134.021851 
                    C152.440842,134.021851 139.379959,134.021851 126.356705,134.021851 
                    C126.356705,128.023102 126.356705,122.478714 126.356705,116.507843 
                    C137.615997,116.507843 148.524658,116.507843 159.368011,116.507843 
                    C159.368011,110.822212 159.368011,105.584595 159.368011,99.976044 
                    C148.113358,99.976044 137.198639,99.976044 126.214920,99.976044 
                    C126.214920,94.502678 126.214920,89.418289 126.214920,83.932831 
                    C139.152802,83.932831 151.886581,83.932831 164.772430,83.932831 
                    C164.772430,78.129379 164.772430,72.732079 164.772430,67.218430 
                    C145.146561,67.218430 125.752022,67.218430 106.278839,67.218430 
                    C106.278839,95.318626 106.278839,123.199654 106.278839,151.254654 
                    C111.873039,151.254654 117.187012,151.254654 123.500725,151.254654 
                M173.992645,74.533348 
                    C173.992645,77.949341 173.992645,81.365341 173.992645,85.146568 
                    C182.772278,85.146568 190.848480,85.146568 199.228210,85.146568 
                    C199.228210,107.261795 199.228210,128.993454 199.228210,150.992249 
                    C206.104477,150.992249 212.659393,150.992249 219.852524,150.992249 
                    C219.852524,139.803284 219.852509,128.873962 219.852509,117.944633 
                    C219.852509,107.040466 219.852524,96.136292 219.852524,84.839493 
                    C224.932892,84.839493 229.648849,84.839493 235.078964,84.839493 
                    C231.661301,81.835159 227.975662,79.870979 226.207840,76.802063 
                    C220.902313,67.591705 213.101822,65.528076 203.389175,66.926193 
                    C200.291321,67.372131 197.075104,66.995964 193.913055,66.995964 
                    C187.327179,66.995964 180.741302,66.995956 173.992645,66.995956 
                    C173.992645,69.593849 173.992645,71.574600 173.992645,74.533348 
                z"/>
                            <path d="
                M311.360413,148.483093 
                    C296.561432,154.711349 282.171326,154.502609 268.635712,146.554184 
                    C255.807404,139.021118 248.920181,127.408180 247.969254,112.313225 
                    C247.228989,100.562263 250.071350,89.982323 257.783966,79.998497 
                    C243.188126,76.131500 232.001678,68.651344 226.609848,54.541630 
                    C225.167343,50.766766 224.399994,46.733910 223.047226,41.818619 
                    C228.900238,46.475296 233.111725,50.700035 238.092697,53.605667 
                    C248.256485,59.534687 259.865723,60.277355 271.247437,60.524445 
                    C286.711273,60.860157 302.207031,60.611164 317.666351,60.018612 
                    C333.369965,59.416702 348.265900,56.292141 358.261749,41.969715 
                    C361.965454,55.178131 348.425079,71.010017 325.858582,80.217827 
                    C336.084808,93.879761 337.866089,109.009789 332.693939,124.842957 
                    C329.198975,135.541931 321.882782,143.330719 311.360413,148.483093 
                M270.651550,98.477081 
                    C269.988129,101.540070 268.789673,104.598053 268.760956,107.666977 
                    C268.672424,117.125221 271.363770,125.364632 280.332977,130.322922 
                    C288.149292,134.643890 296.010406,134.494492 303.560181,130.039154 
                    C315.424530,123.037674 318.099884,103.777054 309.277557,92.563255 
                    C299.675964,80.358994 278.051910,81.281525 270.651550,98.477081 
                z"/>
                            <path d="
                M422.447998,109.859421 
                    C421.916840,89.824776 433.157104,74.256310 450.148712,67.702591 
                    C467.171143,61.137005 487.259674,65.444130 499.327850,79.327995 
                    C511.132111,92.908249 513.100220,108.903084 506.920715,125.522697 
                    C501.105011,141.163925 489.267120,149.573380 472.751099,152.093399 
                    C452.117767,155.241653 433.290100,144.191391 426.741669,128.488739 
                    C424.377228,122.818954 423.826263,116.392906 422.447998,109.859421 
                M466.465088,133.205490 
                    C476.634521,133.251495 483.207428,128.010651 487.292206,119.074852 
                    C492.715118,107.211815 486.687561,90.218727 475.259644,85.991692 
                    C466.125305,82.613022 457.454773,83.637993 450.328583,90.765114 
                    C442.157623,98.937119 441.892822,109.100685 445.278656,119.101303 
                    C448.378937,128.258423 455.700134,132.952606 466.465088,133.205490 
                z"/>
                            <path d="
                M366.649292,67.000458 
                    C373.576782,66.998589 380.068329,66.987976 386.559814,67.000893 
                    C399.326111,67.026283 410.270569,74.579025 413.649933,85.680099 
                    C417.349213,97.832077 413.080322,109.777512 402.003265,117.189056 
                    C399.407410,118.925903 399.840393,119.981285 401.176788,122.209167 
                    C406.761963,131.520157 412.110962,140.972809 418.058929,151.259735 
                    C410.216522,151.259735 403.326996,151.377945 396.450409,151.133560 
                    C395.406616,151.096466 394.128693,149.547638 393.459351,148.404968 
                    C389.452209,141.564011 385.239197,134.806763 381.809204,127.677925 
                    C379.628754,123.146126 376.993469,120.902916 371.929932,121.822983 
                    C370.510162,122.080971 369.003784,121.862343 367.126892,121.862343 
                    C367.126892,131.558823 367.126892,141.141632 367.126892,150.987061 
                    C360.666504,150.987061 354.607391,150.987061 348.031219,150.987061 
                    C348.031219,147.927856 348.031250,144.809357 348.031219,141.690842 
                    C348.031158,124.047234 348.096802,106.403130 347.965240,88.760498 
                    C347.943787,85.882576 348.752502,84.199203 351.029877,82.236313 
                    C355.385345,78.482307 359.047852,73.928398 363.044617,69.751129 
                    C364.007111,68.745193 365.152222,67.913948 366.649292,67.000458 
                M384.860138,84.152519 
                    C379.150696,84.152519 373.441284,84.152519 367.686920,84.152519 
                    C367.686920,91.613838 367.686920,98.671387 367.686920,105.777588 
                    C372.792084,105.777588 377.589630,105.740631 382.386383,105.786011 
                    C390.124908,105.859207 393.385986,103.512337 394.442078,97.017616 
                    C395.507446,90.465424 392.843109,86.571220 384.860138,84.152519 
                z"/>
                            <path d="
                M71.743462,132.889221 
                    C74.940872,127.813339 74.063515,123.361969 68.958450,121.336983 
                    C63.014622,118.979294 56.722309,117.486046 50.554928,115.708931 
                    C37.195251,111.859360 30.061157,103.963615 29.263874,92.037086 
                    C28.573471,81.709366 34.999325,72.648300 47.059700,67.653183 
                    C60.011856,62.288696 82.911011,63.781097 92.372871,78.559502 
                    C88.573830,82.934280 84.892784,87.173187 82.185760,90.290466 
                    C75.721535,87.359558 70.426628,84.451935 64.782944,82.617119 
                    C62.025494,81.720642 58.363464,82.158493 55.585793,83.227005 
                    C53.332809,84.093666 50.594330,86.623596 50.273335,88.748505 
                    C49.964325,90.794060 51.935909,94.396683 53.863686,95.324707 
                    C58.406750,97.511719 63.511314,98.544571 68.405624,99.987007 
                    C76.897995,102.489845 85.411110,105.122215 90.659782,112.967911 
                    C97.578529,123.310020 94.922241,141.050980 80.599335,148.313019 
                    C63.769196,156.846298 37.525024,153.344177 25.149784,135.838577 
                    C28.989302,131.481705 32.901012,127.042908 37.115250,122.260818 
                    C42.295383,128.598465 47.923267,134.199173 56.034973,134.855164 
                    C61.093208,135.264221 66.304016,133.786575 71.743462,132.889221 
                z"/>
                            <path d="
                M571.998901,146.079254 
                    C555.771423,157.058456 526.250549,154.782318 512.775940,135.952667 
                    C516.544373,131.616028 520.403809,127.174667 523.989258,123.048592 
                    C528.891968,126.566208 533.233459,131.070816 538.522888,133.080414 
                    C543.679932,135.039719 549.822632,134.914795 555.497803,134.715469 
                    C557.408508,134.648361 559.934509,131.873154 560.891663,129.754044 
                    C562.235352,126.779083 560.856628,123.497246 557.856812,122.168518 
                    C553.095642,120.059624 548.000244,118.717903 543.095642,116.916939 
                    C537.998657,115.045326 532.515625,113.731003 527.979553,110.918198 
                    C514.138184,102.335167 512.994690,84.056381 525.192383,73.206825 
                    C538.793335,61.109119 565.256836,62.329006 577.496582,75.642532 
                    C578.585510,76.827011 579.507324,78.165115 580.638245,79.598511 
                    C576.437012,83.501953 572.442078,87.213699 569.504089,89.943466 
                    C563.092712,87.211914 557.441223,84.295059 551.455383,82.481720 
                    C548.713318,81.651031 545.137573,82.473106 542.250183,83.456612 
                    C536.719604,85.340408 535.764160,91.882256 540.705078,94.911469 
                    C544.095276,96.989914 548.310242,97.708572 552.133362,99.098618 
                    C558.617188,101.456039 565.660339,102.917282 571.437744,106.443779 
                    C584.144409,114.199890 585.600708,132.132721 575.169861,143.139008 
                    C574.257263,144.101913 573.281616,145.004974 571.998901,146.079254 
                z"/>
                            <path d="
                M123.000854,151.254654 
                    C117.187012,151.254654 111.873039,151.254654 106.278839,151.254654 
                    C106.278839,123.199654 106.278839,95.318626 106.278839,67.218430 
                    C125.752022,67.218430 145.146561,67.218430 164.772430,67.218430 
                    C164.772430,72.732079 164.772430,78.129379 164.772430,83.932831 
                    C151.886581,83.932831 139.152802,83.932831 126.214920,83.932831 
                    C126.214920,89.418289 126.214920,94.502678 126.214920,99.976044 
                    C137.198639,99.976044 148.113358,99.976044 159.368011,99.976044 
                    C159.368011,105.584595 159.368011,110.822212 159.368011,116.507843 
                    C148.524658,116.507843 137.615997,116.507843 126.356705,116.507843 
                    C126.356705,122.478714 126.356705,128.023102 126.356705,134.021851 
                    C139.379959,134.021851 152.440842,134.021851 165.747559,134.021851 
                    C165.747559,139.880798 165.747559,145.297836 165.747559,151.254654 
                    C151.653564,151.254654 137.577148,151.254654 123.000854,151.254654 
                z"/>
                            <path d="
                M173.992645,74.044357 
                    C173.992645,71.574600 173.992645,69.593849 173.992645,66.995956 
                    C180.741302,66.995956 187.327179,66.995964 193.913055,66.995964 
                    C197.075104,66.995964 200.291321,67.372131 203.389175,66.926193 
                    C213.101822,65.528076 220.902313,67.591705 226.207840,76.802063 
                    C227.975662,79.870979 231.661301,81.835159 235.078964,84.839493 
                    C229.648849,84.839493 224.932892,84.839493 219.852524,84.839493 
                    C219.852524,96.136292 219.852509,107.040466 219.852509,117.944633 
                    C219.852509,128.873962 219.852524,139.803284 219.852524,150.992249 
                    C212.659393,150.992249 206.104477,150.992249 199.228210,150.992249 
                    C199.228210,128.993454 199.228210,107.261795 199.228210,85.146568 
                    C190.848480,85.146568 182.772278,85.146568 173.992645,85.146568 
                    C173.992645,81.365341 173.992645,77.949341 173.992645,74.044357 
                z"/>
        </svg>
    );
}

export default SetorosLogo;
import { getIcon, getTextColor, openOnClick } from '../utils';
import Button from './Button';
import Logo from '../assets/Logo';
import Info from '../icon/Info';
import { DOC_LINK, HOMEPAGE_LINK } from '../constants';

function NavBar(props) {

    const DEFAULT_HEIGHT = '80px';
    const HEADER_STYLE = 'primary';
    const LOGO_WIDTH = '242px'
    const BUTTON_SIZE = 'small';
    const BUTTON_STATE = 'enabled';
    const BUTTON_TEXT = 'OPEN APP';

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    return (
        <nav className='flex flex-row items-center justify-between px-15 fixed left-0 top-0 w-full bg-white z-50' style={{ height: getHeight() }}>
            <Logo className="fill-neutral-800" width={LOGO_WIDTH} height={getHeight()} link={HOMEPAGE_LINK} />
            <div className='flex flex-row items-center align-middle p-0 gap-5' style={{ height: getHeight() }}>
                <div onClick={(e) => openOnClick(e, DOC_LINK)}  className='flex flow-row gap-1 cursor-pointer items-center'>                    
                    <Info className="fill-neutral-800" width={16} height={16} />
                    <p className={`font-inter font-bold text-p1 text-center text-neutral-800 select-none`}>
                        Documentation
                    </p>
                </div>
                <button onClick={(e) => openOnClick(e, 'https://app.setoros.com')} className="btn-sm btn-black">Open App</button>
            </div>
        </nav>
    );
}

export default NavBar;

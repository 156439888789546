import { getTextColor } from '../utils';
import MediaFeatureImageMobile3 from '../assets/MediaFeatureImageMobile3.png';

function MediaFeatureMobile3(props) {

    const MEDIA_FEATURE_3_STYLE = 'primary';
    const DEFAULT_HEIGHT = '700px';

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    return (
        <div className='px-4 w-full bg-neutral-50' style={{ height: getHeight() }}>
            <div className='flex flex-col items-center gap-1'>
                <img className='w-auto h-full' src={MediaFeatureImageMobile3} alt='MediaFeature3' />
                <div className='flex flex-col items-center gap-3'>
                    <p className={`font-inter font-bold text-h2 text-center text-neutral-800 select-text`}>
                        Multi-Token
                    </p>
                    <p className={`font-inter font-bold text-h6 text-left text-neutral-800 select-text`}>
                        Don't give up exposure to crypto with our Multi-Token Support. Bet natively in USDC, ETH, BTC, and other tokens.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default MediaFeatureMobile3;
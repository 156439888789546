import { openOnClick } from '../utils';

function Hamburger(props) {
    const DEFAULT_WIDTH = '20px';
    const DEFAULT_HEIGHT = '12px';
    const DEFAULT_FILL_COLOR = 'fill-white';

    const getWidth = () => {
        return props.width === undefined ? DEFAULT_WIDTH : props.width;
    }

    const getHeight = () => {
        return props.height === undefined ? DEFAULT_HEIGHT : props.height;
    }

    const getCursorType = () => {
        if (props.link !== undefined && props.link.length > 0) {
            return 'cursor-pointer';
        }
        return ''; // use default cursor type
    }

    const getClassesWithPrefix = (prefix, classNames) => {
        var classArray = [];
        var classNameArray = classNames.split(' ');
        for (var i = 0; i < classNameArray.length; i++) {
            if (classNameArray[i].startsWith(prefix)) {
                classArray.push(classNameArray[i]);
            }
        }
        return classArray;
    }

    // Assume there is only one fill class, will return first if there is more than one
    const getFillColor = () => {
        if (props.className === undefined) {
            return DEFAULT_FILL_COLOR;
        }
        var fillColorArray = getClassesWithPrefix('fill-', props.className);
        return (fillColorArray === undefined || fillColorArray.length === 0) ? DEFAULT_FILL_COLOR : fillColorArray[0];
    }

    return (
        <svg onClick={(e) => openOnClick(e, props.link)} className={`${props.className} ${getCursorType()}`} viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg' style={{ minWidth: getWidth(), minHeight: getHeight(), width: getWidth(), height: getHeight() }}>
            <path d='M20 10.75C20 10.336 19.664 10 19.25 10H0.75C0.336 10 0 10.336 0 10.75C0 11.164 0.336 11.5 0.75 11.5H19.25C19.664 11.5 20 11.164 20 10.75ZM20 5.75C20 5.336 19.664 5 19.25 5H0.75C0.336 5 0 5.336 0 5.75C0 6.164 0.336 6.5 0.75 6.5H19.25C19.664 6.5 20 6.164 20 5.75ZM20 0.75C20 0.336 19.664 0 19.25 0H0.75C0.336 0 0 0.336 0 0.75C0 1.164 0.336 1.5 0.75 1.5H19.25C19.664 1.5 20 1.164 20 0.75Z' />
        </svg>
    );
}

export default Hamburger;